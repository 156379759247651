import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "tailwindcss/tailwind.css";
import Meeting from "../../../resources/assets/images/meetings.png";
import Press from "../../../resources/assets/images/press.png";
import Testing from "../../../resources/assets/images/testing.png";
import Prototyping from "../../../resources/assets/images/prototyping.png";
import Workshop from "../../../resources/assets/images/workshops.png";

import { Carousel as CarouselPackage } from "react-responsive-carousel";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Item = ({ title, image, description }) => {
  let classes = "";
  if (title === "Product testing") {
    classes = "md:ml-auto md:w-7/12 ";
  }
  return (
    <div className="h-full flex flex-col md:mx-5">
      <div className={`relative flex-grow max-h-full`}>
        <img
          src={image}
          alt="workshops"
          className="absolute w-full left-0 top-0 object-cover h-full object-left-top"
        />
        <p
          className={
            "text-center text-4xl md:text-7xl text-white font-bold drop-shadow-[1px_3px_3px_rgba(0,0,0,0.4)] py-40 " +
            classes
          }
        >
          {title}
        </p>
      </div>
      {description && (
        <div className="bg-[#E8EAEB] py-5 h-2/5 px-2 md:px-20">
          <p className="font-medium tex-base md:text-lg">{description}</p>
        </div>
      )}
    </div>
  );
};

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  & .carousel,
  & .slider-wrapper {
    overflow: visible;
    position: unset;
    max-width: 1000px;
    margin: 0 auto;
  }
`;

const Carousel = () => {
  const { t, i18n } = useTranslation();

  return (
    <CarouselContainer>
      <CarouselPackage
        renderArrowPrev={(click, hasPrev) => {
          if (hasPrev)
            return (
              <div className="hidden md:flex absolute top-0 bottom-0 left-0 p-[15px] items-center justify-center cursor-pointer z-20">
                <div className="aspect-square h-10 bg-white rounded-full w-full items-center justify-center flex">
                  <ArrowForwardIcon className="-scale-x-100" onClick={click} />
                </div>
              </div>
            );
        }}
        renderArrowNext={(click, hasNext) => {
          if (hasNext)
            return (
              <div className="hidden md:flex absolute top-0 bottom-0 right-0 p-[15px] items-center justify-center cursor-pointer z-20">
                <div className="aspect-square h-10 bg-white rounded-full w-full items-center justify-center flex">
                  <ArrowForwardIcon onClick={click} />
                </div>
              </div>
            );
        }}
        showThumbs={false}
        showIndicators={false}
        showArrows={true}
        showStatus={false}
      >
        <Item
          image={Press}
          title={
            i18n.language === "pt" ? (
              <>
                <p>Notícias</p>
                <p>&</p>
                <p>Publicações</p>
              </>
            ) : (
              <>
                <p>Press</p>
                <p>&</p>
                <p>Publications</p>
              </>
            )
          }
        />
        <Item
          image={Meeting}
          title={t("development.slides.1.title")}
          description={t("development.slides.1.description")}
        />
        <Item
          image={Workshop}
          title={t("development.slides.2.title")}
          description={t("development.slides.2.description")}
        />
        <Item
          image={Prototyping}
          title={t("development.slides.3.title")}
          description={t("development.slides.3.description")}
        />
        <Item
          image={Testing}
          title={t("development.slides.4.title")}
          description={t("development.slides.4.description")}
        />
      </CarouselPackage>
    </CarouselContainer>
  );
};

export default Carousel;
