import React from "react";

function CustomInput({
  type,
  id,
  name,
  placeholder,
  value,
  onChange,
  register,
  rules,
}) {
  return (
    <div className="bg-white h-[50px] max-h-[50px] my-2 w-full">
      <input
        className="w-full h-full outline-0 border focus:border-[1.5px] border-[#EDEDED] px-4 focus:border-LightGreen rounded transition-all ease-in  duration-200"
        placeholder={placeholder}
        value={value}
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        {...register(name, rules)}
      />
    </div>
  );
}
export default CustomInput;
