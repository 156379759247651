import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { Wrapper } from "../../components/landingpage/wrapper";
import CustomCheckbox from "../../components/landingpage/CustomCheckbox";
import CustomTextField from "../../components/landingpage/CustomTextField";
import CustomInput from "../../components/landingpage/CustomInput";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Complaint() {
  const { t } = useTranslation();
  const form = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        "template_ypac64e",
        {
          from_name: data.nome,
          from_email: data.email,
          phone_number: data.telemovel,
          report_subject: data.complaint,
          relationship_origin: data.relation,
          report_date: data.date,
          report_description: data.desc,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        () => {
          toast("Contacto enviado");
          form.current.reset();
        },
        () => {
          toast("Erro ao enviar contacto");
        }
      );
  };

  return (
    <div className="pt-20">
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />
      <div className="bg-white pt-32">
        <Wrapper>
          <h3 className="text-3xl text-center font-bold text-Salmon mb-10 ">
            {t("reportingChannel.title")}
          </h3>
          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <p className="font-semibold text-DarkGray">
              {t("reportingChannel.anonymous.question")}
              <span className="text-[#ED1D24]">*</span>
            </p>
            <CustomCheckbox
              label={t("reportingChannel.anonymous.1")}
              name="anonymous"
              id="anonymous1"
              type={"radio"}
              val={"sim"}
              register={register}
              rules={{ required: t("required") }}
            />
            <CustomCheckbox
              label={t("reportingChannel.anonymous.2")}
              name="anonymous"
              id="anonymous2"
              type={"radio"}
              val={"nao"}
              register={register}
              rules={{ required: t("required") }}
            />
            {errors.anonymous && (
              <span className="text-[#ED1D24]">{errors.anonymous.message}</span>
            )}
            {watch("anonymous") !== "sim" && (
              <>
                <p className="font-semibold text-DarkGray mt-6">
                  {t("reportingChannel.name.question")}
                </p>
                <CustomInput
                  placeholder={t("reportingChannel.name.placeholder")}
                  type="text"
                  name={"nome"}
                  id="nome"
                  register={register}
                />
                <div className="flex flex-col md:flex-row md:gap-5">
                  <div className="w-full mt-6">
                    <p className="font-semibold text-DarkGray">
                      {t("reportingChannel.email.question")}
                      <span className="text-[#ED1D24]">*</span>
                    </p>
                    <CustomInput
                      placeholder={t("reportingChannel.email.placeholder")}
                      type="email"
                      name={"email"}
                      register={register}
                      id="email"
                      rules={{ required: t("required") }}
                    />
                    {errors.email && (
                      <span className="text-[#ED1D24]">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full mt-6">
                    <p className="font-semibold text-DarkGray">
                      {t("reportingChannel.phone.question")}
                      <span className="text-[#ED1D24]">*</span>
                    </p>
                    <CustomInput
                      placeholder={t("reportingChannel.phone.placeholder")}
                      type="number"
                      name={"telemovel"}
                      register={register}
                      id="telemovel"
                      rules={{ required: t("required") }}
                    />
                    {errors.telemovel && (
                      <span className="text-[#ED1D24]">
                        {errors.telemovel.message}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col md:flex-row md:gap-5">
              <div className="w-full flex flex-col mt-6">
                <p className="font-semibold text-DarkGray flex-grow">
                  {t("reportingChannel.subject.question")}
                  <span className="text-[#ED1D24]">*</span>
                </p>
                <CustomInput
                  placeholder={t("reportingChannel.subject.placeholder")}
                  type="text"
                  name={"complaint"}
                  register={register}
                  id="complaint"
                  rules={{ required: t("required") }}
                />
                {errors.complaint && (
                  <span className="text-[#ED1D24]">
                    {errors.complaint.message}
                  </span>
                )}
              </div>
              <div className="w-full mt-6">
                <p className="font-semibold text-DarkGray">
                  {t("reportingChannel.relationship.question")}
                  <span className="text-[#ED1D24]">*</span>
                </p>
                <CustomInput
                  placeholder={t("reportingChannel.relationship.placeholder")}
                  type="text"
                  name={"relation"}
                  register={register}
                  id="relation"
                  rules={{ required: t("required") }}
                />
                {errors.relation && (
                  <span className="text-[#ED1D24]">
                    {errors.relation.message}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full md:w-1/2 md:pr-[10px] mt-6">
              <p className="font-semibold text-DarkGray">
                {t("reportingChannel.date.question")}
                <span className="text-[#ED1D24]">*</span>
              </p>
              <CustomInput
                register={register}
                type="date"
                name={"date"}
                id="date"
                rules={{ required: t("required") }}
              />
              {errors.date && (
                <span className="text-[#ED1D24]">{errors.date.message}</span>
              )}
            </div>
            <div className="w-full">
              <p className="font-semibold text-DarkGray mt-6">
                {t("reportingChannel.description.question")}
                <span className="text-[#ED1D24]">*</span>
              </p>
              <CustomTextField
                register={register}
                placeholder={t("reportingChannel.description.placeholder")}
                name={"desc"}
                id="desc"
                rules={{ required: t("required") }}
              />
              {errors.desc && (
                <span className="text-[#ED1D24]">{errors.desc.message}</span>
              )}
            </div>
            <div className="border border-LightGray rounded w-full p-4 mt-10">
              <CustomCheckbox
                squared
                label={t("reportingChannel.consents.1")}
                register={register}
                name="consent1"
                rules={{
                  required: t("required"),
                }}
              />
              {errors.consent1 && (
                <span className="text-[#ED1D24]">
                  {errors.consent1.message}
                </span>
              )}
              <CustomCheckbox
                squared
                label={t("reportingChannel.consents.2")}
                register={register}
                name="consent2"
                rules={{
                  required: t("required"),
                }}
              />
              {errors.consent2 && (
                <span className="text-[#ED1D24]">
                  {errors.consent2.message}
                </span>
              )}
              <CustomCheckbox
                squared
                label={t("reportingChannel.consents.3")}
                register={register}
                name="consent3"
                rules={{
                  required: t("required"),
                }}
              />
              {errors.consent3 && (
                <span className="text-[#ED1D24]">
                  {errors.consent3.message}
                </span>
              )}
            </div>
            <div className="justify-center flex my-10">
              <button
                type="submit"
                className="bg-[#669581] px-10 py-4 uppercase text-white font-bold rounded text-sm"
              >
                {t("reportingChannel.submit")}
              </button>
            </div>
          </form>
        </Wrapper>
      </div>
    </div>
  );
}

export default Complaint;
