import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as DesignCube } from "../../../resources/assets/colab/design.svg";
import { ReactComponent as ProductionCube } from "../../../resources/assets/colab/production.svg";
import { ReactComponent as ResearchCube } from "../../../resources/assets/colab/research.svg";
import { ReactComponent as ScienceCube } from "../../../resources/assets/colab/science.svg";

import { ReactComponent as PtDesignCube } from "../../../resources/assets/colab/pt-design.svg";
import { ReactComponent as PtProductionCube } from "../../../resources/assets/colab/pt-production.svg";
import { ReactComponent as PtResearchCube } from "../../../resources/assets/colab/pt-research.svg";
import { ReactComponent as PtScienceCube } from "../../../resources/assets/colab/pt-science.svg";
import Banana from "../../../resources/assets/colab/banana.png";
import "animate.css";
import { Box, Modal, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

function Collab() {
  const containerRef = useRef();
  const [animate, setAnimate] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const { i18n, t } = useTranslation();

  const containerInView = useIsInViewport(containerRef);

  useEffect(() => {
    if (containerInView) {
      setAnimate(true);
    }
  }, [containerInView]);

  const desktop = useMediaQuery("(min-width:600px)");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#FFF",
    padding: "20px 40px 60px 40px",
    alignItems: "center",
    gap: 20,
    display: "flex",
    flexDirection: "column",
  };

  const modalMobileStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#FFF",
    padding: "20px 40px 60px 40px",
    alignItems: "center",
    gap: 20,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div
      ref={containerRef}
      className="bg-[#9BD0B0] justify-center items-center overflow-hidden"
    >
      <div className="py-20 flex flex-col items-center relative z-[1] w-9/12 lg:w-fit mx-auto">
        <div
          className={
            animate && "animate__animated animate__slideInDown w-full z-40"
          }
        >
          <div
            className={`flex flex-row items-end z-10 relative w-full translate-y-[6%]`}
          >
            <div className="flex flex-col w-1/2 translate-x-[6%]">
              <div className="translate-y-[35%] z-[40]">
                <img
                  src={Banana}
                  alt=""
                  className={`w-full relative z-20 ${
                    animate && "animate__animated animate__slideInDown"
                  } `}
                />
              </div>
              <div
                className={
                  animate && "animate__animated animate__slideInLeft w-full"
                }
                onClick={() => {
                  setModalTitle(t("research.title"));
                  setModalDescription(t("research.description"));
                  setOpen(true);
                }}
              >
                {i18n.language === "pt" ? (
                  <PtResearchCube className={`h-fit w-full cursor-pointer`} />
                ) : (
                  <ResearchCube className={`h-fit w-full cursor-pointer`} />
                )}
              </div>
            </div>
            <div
              className={
                animate && "animate__animated animate__slideInRight w-1/2"
              }
              onClick={() => {
                setModalTitle(t("science.title"));
                setModalDescription(t("science.description"));
                setOpen(true);
              }}
            >
              {i18n.language === "pt" ? (
                <PtScienceCube
                  className={`-translate-x-[6%] h-fit w-full cursor-pointer`}
                />
              ) : (
                <ScienceCube
                  className={`-translate-x-[6%] h-fit w-full cursor-pointer`}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={animate && "animate__animated animate__slideInUp w-full"}
        >
          <div className={`flex flex-row items-end w-full -translate-y-[7%]`}>
            <div
              className={
                animate && "animate__animated animate__slideInLeft w-1/2"
              }
              onClick={() => {
                setModalTitle(t("design.title"));
                setModalDescription(t("design.description"));
                setOpen(true);
              }}
            >
              {i18n.language === "pt" ? (
                <PtDesignCube
                  className={`translate-x-[7%] h-fit w-full cursor-pointer`}
                />
              ) : (
                <DesignCube
                  className={`translate-x-[7%] h-fit w-full cursor-pointer`}
                />
              )}
            </div>
            <div
              className={
                animate && "animate__animated animate__slideInRight w-1/2"
              }
              onClick={() => {
                setModalTitle(t("production.title"));
                setModalDescription(t("production.description"));
                setOpen(true);
              }}
            >
              {i18n.language === "pt" ? (
                <PtProductionCube
                  className={`-translate-x-[7%] h-fit w-full cursor-pointer`}
                />
              ) : (
                <ProductionCube
                  className={`-translate-x-[7%] h-fit w-full cursor-pointer`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={desktop ? modalStyle : modalMobileStyle}>
          <div className="w-full flex justify-end md:hidden">
            <CloseIcon
              fontSize="large"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <h2 className="text-[#000000] text-2xl font-medium uppercase text-center">
            {modalTitle}
          </h2>
          <p className="text-justify">{modalDescription}</p>
        </Box>
      </Modal>
    </div>
  );
}

export default Collab;
