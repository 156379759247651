import React, { useState } from "react";
import Process1 from "../../../resources/assets/process/icon-1.png";
import Process2 from "../../../resources/assets/process/icon-2.png";
import Process3 from "../../../resources/assets/process/icon-3.png";
import Process4 from "../../../resources/assets/process/icon-4.png";
import Process5 from "../../../resources/assets/process/icon-5.png";
import Process6 from "../../../resources/assets/process/icon-6.png";
import { ReactComponent as Fiber } from "../../../resources/assets/process/fiber.svg";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useTranslation } from "react-i18next";

const CarouselContainer = styled.div`
  & .carousel,
  & .slider-wrapper {
    overflow: visible;
  }
`;

const Item = ({ image, description, setCurrentItem, index }) => {
  return (
    <div
      onClick={() => {
        setCurrentItem(index);
      }}
      className="flex flex-col relative w-fit px-2 mx-auto"
    >
      <div className="group">
        <img
          alt=""
          src={image}
          className="max-w-[150px] h-[150px] mx-auto border-4 border-[#006536] rounded-full transition-transform duration-500 transform translate-y-0 group-hover:-translate-y-[70%] z-10"
        />
        <div className="select-none absolute bottom-0 left-0 rounded-b-full rounded-tr-full bg-white max-w-[150px] min-h-[70px] items-center justify-center flex flex-col opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-0">
          <p className="text-center mx-2">{description}</p>
        </div>
      </div>
    </div>
  );
};

function Process() {
  const [currentItem, setCurrentItem] = useState(1);
  const { t } = useTranslation();
  return (
    <div className="relative bg-[#FDE2A5] overflow-hidden">
      <Fiber className="absolute -left-10 -bottom-5" />
      <Fiber className="absolute -top-10 -right-20" />
      <div className="pb-20">
        <p className="uppercase text-2xl font-bold text-center pt-10 pb-20 text-[#006536]">
          {t("process.title")}
        </p>
        <div className="md:flex lg:hidden hidden flex-col items-center">
          <div className="flex flex-row gap-10 justify-center  pt-28">
            <Item image={Process1} description={t("process.1")} />
            <Item image={Process2} description={t("process.2")} />
            <Item image={Process3} description={t("process.3")} />
          </div>
          <div className="flex flex-row gap-10 justify-center pt-28">
            <Item image={Process4} description={t("process.4")} />
            <Item image={Process5} description={t("process.5")} />
            <Item image={Process6} description={t("process.6")} />
          </div>
        </div>
        <div className="flex-row gap-10 justify-center hidden lg:flex pt-28">
          <Item image={Process1} description={t("process.1")} />
          <Item image={Process2} description={t("process.2")} />
          <Item image={Process3} description={t("process.3")} />
          <Item image={Process4} description={t("process.4")} />
          <Item image={Process5} description={t("process.5")} />
          <Item image={Process6} description={t("process.6")} />
        </div>
        <CarouselContainer className="w-5/12 mx-auto md:hidden pt-28">
          <Carousel
            selectedItem={currentItem}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            onChange={(index) => {
              setCurrentItem(index);
            }}
          >
            <Item
              setCurrentItem={setCurrentItem}
              index={0}
              image={Process1}
              description={t("process.1")}
            />
            <Item
              setCurrentItem={setCurrentItem}
              index={1}
              image={Process2}
              description={t("process.2")}
            />
            <Item
              setCurrentItem={setCurrentItem}
              index={2}
              image={Process3}
              description={t("process.3")}
            />
            <Item
              setCurrentItem={setCurrentItem}
              index={3}
              image={Process4}
              description={t("process.4")}
            />
            <Item
              setCurrentItem={setCurrentItem}
              index={4}
              image={Process5}
              description={t("process.5")}
            />
            <Item
              setCurrentItem={setCurrentItem}
              index={5}
              image={Process6}
              description={t("process.6")}
            />
          </Carousel>
        </CarouselContainer>
      </div>
    </div>
  );
}

export default Process;
