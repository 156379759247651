function GalleryModalComponent({ src, setImage }) {
  const handleModal = () => {
    setImage(src);
  };

  return (
    <>
      <div className="w-full">
        <a href={false} onClick={handleModal} className="cursor-pointer">
          <img src={src} alt="" />
        </a>
      </div>
    </>
  );
}

export default GalleryModalComponent;
