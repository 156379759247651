import { AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomAccordion } from "./styles/index_styles";
import { useSearchParams } from "react-router-dom";
import { Wrapper } from "../../components/landingpage/wrapper";
import { useTranslation } from "react-i18next";

function PolicyPrivacy() {
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  function Sections(props) {
    const { tabValue, children } = props;
    return (
      <div className={tabValue === value ? "block" : "hidden"}>{children}</div>
    );
  }
  useEffect(() => {
    if (searchParams.get("tab") === "0") {
      handleChange(0);
    } else if (searchParams.get("tab") === "1") {
      handleChange(1);
    }
  }, [searchParams]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  return (
    <div className="pt-20">
      <div className="pt-32 bg-white pb-20 relative z-[1]">
        <Wrapper>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="flex flex-col basis-1/4 text-Gray gap-5">
              <p
                onClick={() => handleChange(0)}
                className={`font-bold uppercase cursor-pointer ${
                  value === 0 && "text-darkGreen"
                }`}
              >
                {t("policy-page.privacy")}
              </p>
              <p
                onClick={() => handleChange(1)}
                className={`font-bold uppercase cursor-pointer ${
                  value === 1 && "text-darkGreen"
                }`}
              >
                {t("policy-page.terms")}
              </p>
              <a
                href={"https://www.livroreclamacoes.pt/Inicio/"}
                className="font-bold uppercase"
                target="_blank"
                rel="noreferrer"
              >
                {t("policy-page.compliments")}
              </a>
            </div>
            <div className="flex flex-col basis-3/4">
              <Sections tabValue={0}>
                <h3 className="text-2xl md:text-3xl font-bold text-darkGreen">
                  {t("privacy.title")}
                </h3>
                <p className="text-sm text-LightGreen uppercase font-extrabold mb-10">
                  {t("privacy.date")}
                </p>
                <div className="flex flex-col gap-5">
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.1.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.1.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.2.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.2.content")}</p>
                      <li className="mt-3">{t("privacy.2.content1")}</li>
                      <p>{t("privacy.2.content2")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.3.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.3.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.4.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.4.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.5.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.5.content")}</p>
                      <p>{t("privacy.5.content1")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("privacy.6.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("privacy.6.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <h3 className="text-2xl md:text-3xl font-bold text-darkGreen">
                    {t("cookies.title")}
                  </h3>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("cookies.1.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("cookies.1.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("cookies.2.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("cookies.2.content")}</p>
                      <p>{t("cookies.2.content1")}</p>
                      <p>{t("cookies.2.content2")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("cookies.3.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("cookies.3.content")}</p>
                      <p>{t("cookies.3.content1")}</p>
                      <p>{t("cookies.3.content2")}</p>
                      <p>{t("cookies.3.content3")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("cookies.4.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("cookies.4.content")}</p>
                      <p>{t("cookies.4.content1")}</p>
                      <p>{t("cookies.4.content2")}</p>
                      <p>{t("cookies.4.content3")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("cookies.5.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("cookies.5.content")}</p>
                      <p>{t("cookies.5.content1")}</p>
                      <p>{t("cookies.5.content2")}</p>
                      <p>{t("cookies.5.content3")}</p>
                      <p>{t("cookies.5.content4")}</p>
                      <p>{t("cookies.5.content5")}</p>
                      <p>{t("cookies.5.content6")}</p>
                      <p>{t("cookies.5.content7")}</p>
                      <p>{t("cookies.5.content8")}</p>
                      <p>{t("cookies.5.content9")}</p>
                      <p>{t("cookies.5.content10")}</p>
                      <p>{t("cookies.5.content11")}</p>
                      <p>{t("cookies.5.content12")}</p>
                      <p>{t("cookies.5.content13")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                </div>
              </Sections>
              <Sections tabValue={1}>
                <h3 className="text-2xl md:text-3xl font-bold text-darkGreen ">
                  {t("terms.title")}
                </h3>
                <p className="text-sm text-LightGreen uppercase font-extrabold mb-10">
                  {t("terms.date")}
                </p>
                <div className="flex flex-col gap-5">
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("terms.1.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("terms.1.content")}</p>
                      <p>{t("terms.1.content1")}</p>
                      <p>{t("terms.1.content2")}</p>
                      <p>{t("terms.1.content3")}</p>
                      <p>{t("terms.1.content4")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("terms.2.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("terms.2.content")}</p>
                      <p>{t("terms.2.content1")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("terms.3.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("terms.3.content")}</p>
                      <p>{t("terms.3.content1")}</p>
                      <p>{t("terms.3.content2")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("terms.4.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("terms.4.content")}</p>
                      <p>{t("terms.4.content1")}</p>
                      <p>{t("terms.4.content2")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                  <CustomAccordion elevation={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p>{t("terms.5.title")}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{t("terms.5.content")}</p>
                    </AccordionDetails>
                  </CustomAccordion>
                </div>
              </Sections>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
}
export default PolicyPrivacy;
