import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../components/landingpage/wrapper";
import { GridContainer } from "../../components/gallery/grid";
import GalleryModalComponent from "../../components/gallery/GalleryModalComponent";
import images from "../../components/gallery/images";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Gallery() {
  const { t } = useTranslation();
  const [image, setImage] = useState(false);
  const handleClose = () => {
    setImage(null);
  };
  return (
    <div className="py-20">
      <div className="bg-white pt-32">
        <Wrapper>
          <h3 className="text-3xl text-center font-bold text-Salmon mb-10 ">
            {t("gallery.title")}
          </h3>
          <GridContainer>
            {images.map((src, index) => (
              <GalleryModalComponent
                key={index}
                src={src}
                setImage={setImage}
              />
            ))}
          </GridContainer>
        </Wrapper>
      </div>
      <Modal open={image} onClose={handleClose}>
        <Box className="bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  overflow-y-auto  w-[100vw] h-[100vh] md:w-fit justify-center text-center">
          <CloseIcon
            className="absolute right-5 top-3 opacity-50"
            fontSize={"large"}
            onClick={handleClose}
          />
          <img
            src={image}
            alt=""
            className="md:max-h-[100vh] w-full h-full object-contain"
          />
        </Box>
      </Modal>
    </div>
  );
}

export default Gallery;
