import React from "react";
import Arditi from "../../../resources/pages/landingpage/images/arditi.svg";
import Uma from "../../../resources/pages/landingpage/images/uma.svg";
import Biomask from "../../../resources/assets/logos/Logo-Biomask.svg";
import Bam from "../../../resources/assets/logos/bam-logo.svg";
import Gesba from "../../../resources/assets/logos/gesba-logo.svg";
import { useTranslation } from "react-i18next";

function Partners() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#1B7E6C] flex flex-col items-center w-full h-fit md:pb-[130px] pb-20">
      <div className="md:pt-20 md:pb-5 pt-10 pb-6 md:text-[48px] text-[28px] md:font-semibold font-bold text-center uppercase text-[#FFC61C]">
        {t("consortium")}
      </div>
      <div className="bg-white flex flex-col items-center justify-center w-full md:py-10 py-8">
        <div className="md:w-2/3 w-full flex md:flex-row flex-col md:justify-between gap-8 items-center">
          <img src={Biomask} alt="" className="md:w-[145px] w-[106px]" />
          <img src={Uma} alt="" className="md:w-[198px] w-[165px]" />
          <img src={Arditi} alt="" className="md:w-[272px] w-[219px]" />
        </div>
      </div>
      <div className="md:pt-20 md:pb-5 pt-10 pb-6 md:text-[48px] text-[28px] md:font-semibold font-bold text-center uppercase text-[#FFC61C]">
        {t("partners")}
      </div>
      <div className="bg-white flex flex-col items-center justify-center w-full md:py-10 py-8">
        <div className="md:w-2/3 w-full flex md:flex-row flex-col md:justify-between gap-8 items-center">
          <img src={Bam} alt="" className="md:w-[230px] w-[136px]" />
          <img src={Gesba} alt="" className="md:w-[247px] w-[141px]" />
        </div>
      </div>
    </div>
  );
}

export default Partners;
