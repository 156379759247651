import React from "react";
import LabBiomask from "../../../resources/assets/images/lab_biomask.png";
import MissionBg from "../../../resources/assets/images/mission-bg.png";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

function Mission() {
  const { t } = useTranslation();
  const mdMediaQuery = useMediaQuery("(min-width:790px)");
  return (
    <div>
      <div
        className="w-full h-[100vh] flex flex-col justify-end"
        style={{
          backgroundImage: `url(${LabBiomask})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: mdMediaQuery ? "center" : "75%",
        }}
      >
        <div className="w-full h-fit bg-[#FFFFFFE5] p-6 md:px-16 md:py-5">
          <div className="text-comfortaa font-medium md:text-lg text-sm text-center text-black">
            {t("firstText")}
          </div>
        </div>
      </div>
      <div
        className="w-full h-[95vh] flex flex-col justify-center items-center px-8 md:px-0"
        style={{
          backgroundImage: `url(${MissionBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-fit h-fit flex flex-col md:gap-6 gap-7">
          <div
            className="md:text-[48px] text-[32px] uppercase font-medium text-center text-white"
            style={{
              textShadow: "0px 2px 4px #00000040",
              fontFamily: "Poppins",
            }}
          >
            {t("mission.title")}
          </div>
          <div
            className="text-comfortaa md:text-xl text-base text-white font-medium text-center max-w-[400px]"
            style={{ textShadow: "0px 2px 4px #00000040" }}
          >
            {t("mission.description")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
