import React from "react";
import { useMediaQuery } from "@mui/material";
import AboutImg from "../../../resources/assets/images/bananeira1.png";
import { useTranslation } from "react-i18next";

function About() {
  const mdMediaQuery = useMediaQuery("(min-width:1024px)");
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-row lg:h-fit">
      <div
        className="flex p-6 lg:p-0 flex-col items-center lg:basis-1/2 w-full lg:bg-white lg:py-10"
        style={{
          backgroundImage: !mdMediaQuery && `url(${AboutImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="p-6 lg:p-0 lg:px-6 flex flex-col items-center h-fit lg:bg-white bg-[#006536CC]">
          <div className="lg:text-[#1B7E6C] text-white lg:font-bold font-medium lg:text-[40px] text-[32px] uppercase text-center lg:h-28 mb-5 lg:mb-0">
            {t("about.title")}
          </div>
          <div className="lg:text-black text-white lg:text-lg text-base font-normal lg:text-justify text-center">
            {t("about.text.par1")}
          </div>
          <div className="lg:text-black mt-7 text-white lg:text-lg text-base font-normal lg:text-justify text-center">
            {t("about.text.par2")}
          </div>

          <div className="lg:text-black mt-7 text-white lg:text-lg text-base font-normal lg:text-justify text-center lg:hidden">
            {t("about.text.par3")}
          </div>
          <div className="lg:text-black mt-7 text-white lg:text-lg text-base font-normal lg:text-justify text-center lg:hidden">
            {t("about.text.par4")}
          </div>
        </div>
      </div>
      <div
        className="lg:flex lg:basis-5/12 hidden"
        style={{
          backgroundImage: `url(${AboutImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="lg:flex lg:basis-1/2 hidden px-6 lg:py-10">
        <div className="flex flex-col items-center justify-center h-fit">
          <div className="lg:h-28 w-full" />
          <div className="text-black text-lg font-normal lg:text-justify">
            {t("about.text.par3")}
          </div>
          <div className="text-black text-lg font-normal lg:text-justify mt-7">
            {t("about.text.par4")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
