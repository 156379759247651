import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "styled-components";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import TeamItem from "../../../resources/components/landingpage/teamItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as VipaLogo } from "../../../resources/assets/logos/vipa.svg";

const CarouselContainer = styled.div`
  & .swiper.swiper-horizontal {
    overflow: visible;
    position: unset;
  }

  & .swiper-slide {
    height: 100%;
  }
`;

function Team() {
  const members = [
    {
      member: "tatiana",
    },
    {
      member: "shujoy",
    },
    {
      member: "carlos",
    },
    {
      member: "carla",
    },
    {
      member: "maria",
    },
    {
      member: "duarte",
    },
    {
      member: "oinamo",
    },
    {
      member: "hugo",
    },
    {
      member: "claudia",
    },
    {
      member: "alexandra",
    },
    {
      member: "emanuel",
    },
    {
      member: "vitor",
    },
    {
      member: "jeremy",
    },
    {
      member: "dirk",
    },
    { member: "ricardo" },
    {
      member: "amit",
    },
    {
      member: "harshit",
    },
  ];

  const { t } = useTranslation();

  return (
    <div id="material" className="relative">
      <div className="bg-[#9BD0B0]">
        <p className="uppercase text-3xl font-bold text-center py-10 text-[#000000]">
          Meet the team
        </p>
      </div>
      <div className="w-full relative overflow-hidden bg-[#9BD0B0] py-10">
        <CarouselContainer className="w-11/12 max-w-md md:w-4/5 lg:w-[30%] mx-auto">
          <Swiper>
            {members.map((item, index) => {
              return (
                <SwiperSlide>
                  {({ isActive }) => (
                    <TeamItem
                      member={item.member}
                      key={index}
                      index={index}
                      currentMember={isActive ? index : -1}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </CarouselContainer>
      </div>
      <div className="bg-[#1B7E6C]">
        <p className="md:pt-20 md:pb-5 pt-10 pb-6 md:text-[48px] text-[28px] md:font-semibold font-bold text-center uppercase text-[#FFC61C]">
          {t("aboutCompany.title")}
        </p>
      </div>
      <div className="bg-[#FFF] py-10 flex items-center justify-center px-10">
        <div className="flex flex-col sm:flex-row items-center gap-5 justify-between max-w-7xl">
          <div className="bg-[#FFF] flex flex-col items-center gap-2">
            <p className="font-normal text-[#3A3A3A] font-[Inter] text-lg">
              {t("aboutCompany.text1")}
            </p>
            <p className="font-normal text-[#3A3A3A] font-[Inter] text-lg">
              {t("aboutCompany.text2")}
            </p>
          </div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.vipacorporation.com/"
          >
            <VipaLogo className="w-52 h-fit" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Team;
