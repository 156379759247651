import { Box, Modal } from "@mui/material";
import { useState } from "react";
import { ProductText, ProductTitle } from "./styles/styles";
import ProductImage from "../../assets/images/productV2.png";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalComponent() {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    console.log("click");
    setOpenModal(true);
  };

  const { t } = useTranslation();

  return (
    <>
      <a
        href={false}
        onClick={handleOpen}
        className="text-[#444446] text-[32px] md:text-base font-medium md:font-normal cursor-pointer"
      >
        {t("product.title")}
      </a>

      <Modal open={openModal} onClose={handleClose}>
        <Box className="bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 overflow-y-auto max-h-[100vh] max-w-[90vh] md:h-fit h-full w-full justify-center text-center">
          <CloseIcon
            className="absolute right-10 opacity-50"
            fontSize={"large"}
            onClick={handleClose}
          />
          <ProductTitle>{t("product.title")}</ProductTitle>
          <div className="w-fit m-auto">
            <img src={ProductImage} alt="" />
          </div>
          <div className="flex md:gap-20 justify-center md:flex-row flex-col gap-2">
            <div className="flex flex-col gap-2">
              <ProductText>{t("product.text1")}</ProductText>
              <ProductText>{t("product.text2")}</ProductText>
              <ProductText>{t("product.text3")}</ProductText>
            </div>
            <div className="flex flex-col gap-2">
              <ProductText>{t("product.text4")}</ProductText>
              <ProductText>{t("product.text5")}</ProductText>
              <ProductText>{t("product.text6")}</ProductText>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
