import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Fiber } from "../../../resources/assets/images/fiber-project.svg";
import Carousel from "../../../resources/components/landingpage/carousel";
import { FilePlaceHolder } from "../../../resources/components/landingpage/imageplaceholder";

function TechnicalInfo() {
  const { t } = useTranslation();
  return (
    <div id="tecnica" className="bg-[#fff] py-10 relative ">
      <h2 className="text-black font-bold text-2xl text-center uppercase mb-5 sm:text-2xl md:text-3xl lg:text-4xl">
        {t("technical.title")}
      </h2>
      <FilePlaceHolder />
      <Fiber className="absolute -left-1/5 top-[25%]" />

      <div className="w-full py-20">
        <h2 className="text-black font-bold text-2xl text-center uppercase mb-5 sm:text-2xl md:text-3xl lg:text-4xl">
        {t("development.title")}
        </h2>
        <div className="mt-20">
          <Carousel />
        </div>
      </div>
    </div>
  );
}

export default TechnicalInfo;
