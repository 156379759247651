import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PortugalFlag from "../../assets/images/portugal.png";
import UKFlag from "../../assets/images/united_kingdom.png";
import ColoredLogo from "../../assets/logos/colored-logo.svg";
import Logo from "../../assets/logos/logo.svg";
import { NavigationBar } from "./styles/styles";
import ModalComponent from "./ModalComponent";

function NavigationAppBar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (open) setOpen(false);
  };

  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, [scrolled]);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <NavigationBar
      className={(scrolled || window.location.pathname !== "/") && "scrolled"}
    >
      <div className="w-full h-full flex flex-row items-center justify-between md:mx-16 mx-7 md:pt-0">
        <Link to={"/"}>
          <img src={Logo} alt="" className="md:w-44 w-48" />
        </Link>
        <div className="md:flex flex-row items-center hidden gap-12">
          <ModalComponent />
          <a
            href={"/gallery"}
            className="text-[#444446] text-[32px] md:text-base font-medium md:font-normal cursor-pointer"
          >
            {t("gallery.title")}
          </a>
          <div className="lang items-center md:flex text-[#444446] text-base font-normal relative">
            <span
              onClick={() => changeLanguage("pt")}
              className="w-8 text-center pb-1 cursor-pointer"
            >
              PT
            </span>
            <span className="w-3 pb-1">/</span>
            <span
              onClick={() => changeLanguage("en")}
              className="w-8 text-center pb-1 cursor-pointer"
            >
              EN
            </span>
            <span
              style={{
                left: i18n.language === "pt" ? 0 : "42px",
              }}
              className="w-8 text-center absolute bottom-0 border-b-2 border-[#444446] transition-all duration-500 ease-in"
            />
          </div>
        </div>
        <div className="md:hidden block">
          <IconButton className="pb-5 w-10" onClick={openDrawer}>
            <MenuIcon htmlColor="#656565" fontSize="large" />
          </IconButton>
        </div>
        <Drawer anchor="right" open={open} onClose={closeDrawer}>
          <div className="w-screen h-full bg-[#9BD0B0] p-8 flex flex-col">
            <div className="w-full flex flex-row justify-end">
              <IconButton className="w-10" onClick={closeDrawer}>
                <CloseIcon htmlColor="#656565" fontSize="large" />
              </IconButton>
            </div>
            <div className="w-full h-fit flex flex-col items-center justify-center my-36">
              <ModalComponent />
              <a
                href={"/gallery"}
                className="text-[#444446] text-[32px] md:text-base font-medium md:font-normal cursor-pointer pt-5"
              >
                {t("gallery.title")}
              </a>
              <div className="mt-12 flex flex-col items-center gap-8">
                <img
                  onClick={() => changeLanguage("pt")}
                  src={PortugalFlag}
                  alt=""
                />
                <img onClick={() => changeLanguage("en")} src={UKFlag} alt="" />
              </div>
              <img src={ColoredLogo} alt="" className="mt-64" />
            </div>
          </div>
        </Drawer>
      </div>
    </NavigationBar>
  );
}

export default NavigationAppBar;
