import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;
  padding-top: 20px;

  /* Media query for screens with a maximum width of 768px (smaller screens) */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
