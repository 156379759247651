import React from "react";
import Logo from "../../assets/logos/logo-branco.svg";
import { ReactComponent as LogoBiomaskCompany } from "../../assets/logos/Logo-Biomask.svg";
import { ReactComponent as LogoVipaConnect } from "../../assets/logos/logo-vc.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { useTranslation } from "react-i18next";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { Link } from "react-router-dom";
import moment from "moment";

function Footer() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="w-full bg-[#9BD0B0] flex flex-row items-baseline md:px-16 md:py-14 px-6 py-8">
        <div className="w-full flex md:flex-row flex-col-reverse items-center md:justify-between">
          <div className="md:w-1/3 w-full flex flex-row justify-between">
            <img src={Logo} alt="" className="md:w-[273px] w-[115px] md:pr-5" />
            <div className="md:hidden flex flex-row items-center gap-3">
              <div className="text-base font-medium text-white">
                {t("footer.social")}
              </div>
              <div className="flex flex-row gap-2 items-center">
                <IconButton
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=100078207932699",
                      "_blank"
                    )
                  }
                >
                  <FacebookOutlinedIcon htmlColor="#A4A4A4" />
                </IconButton>

                <IconButton
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/thebiomaskcompany/",
                      "_blank"
                    )
                  }
                >
                  <InstagramIcon htmlColor="#A4A4A4" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flex md:w-2/3 md:flex-row flex-col-reverse md:justify-between gap-6 md:mb-0 mb-5">
            <div className="flex flex-col md:items-start items-center md:gap-5 gap-3">
              <div className="text-white font-bold md:text-[36px] text-[30px] md:text-start text-center">
                {t("footer.policy.title")}
              </div>
              <div className="flex flex-col md:text-left text-center gap-2">
                <Link
                  to="/privacy-policy?tab=0"
                  className="text-white md:font-normal font-medium text-base"
                >
                  {t("footer.policy.privacy")}
                </Link>
                <Link
                  to="/privacy-policy?tab=1"
                  className="text-white md:font-normal font-medium text-base"
                >
                  {t("footer.policy.terms")}
                </Link>
                <Link
                  to={"/complaint"}
                  className="text-white md:font-normal font-medium text-base"
                >
                  {t("footer.policy.reports")}
                </Link>
              </div>
            </div>
            <div className="flex md:w-1/2 flex-col md:items-start items-center md:gap-5 gap-3">
              <div className="text-white font-bold md:text-[36px] text-[30px] md:text-start text-center">
                {t("footer.contacts.title")}
              </div>
              <div className="flex flex-col md:text-left text-center gap-2">
                <div className="text-white md:font-normal font-medium text-base">
                  {t("footer.contacts.tel")}:{" "}
                  <a href={"tel:+351 291 103 690"}>(+351) 291 103 690</a>
                </div>
                <div className="text-white md:font-normal font-medium text-base">
                  {t("footer.contacts.email")}:{" "}
                  <a href={"mailto:info@biomask.pt"}>info@biomask.pt</a>
                </div>
                <div
                  className="text-white md:font-normal font-medium text-base"
                  onClick={() =>
                    window.open(
                      "https://goo.gl/maps/G4TibJf1UkRSwbYFA",
                      "_blank"
                    )
                  }
                >
                  {t("footer.contacts.address")}: Rua Dr. Fernão de Ornelas,
                  nº56, 4º AC 9050-021 Funchal
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/6 h-full md:flex flex-row justify-center items-baseline hidden">
          <div className="flex flex-col gap-3 items-center">
            <div className="text-base font-medium text-white text-center">
              {t("footer.social")}
            </div>
            <div className="flex flex-row gap-2 items-center">
              <IconButton
                sx={{
                  backgroundColor: "#FFF",
                  "&:hover": { backgroundColor: "#FFF" },
                }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100078207932699",
                    "_blank"
                  )
                }
              >
                <FacebookOutlinedIcon htmlColor="#A4A4A4" />
              </IconButton>

              <IconButton
                sx={{
                  backgroundColor: "#FFF",
                  "&:hover": { backgroundColor: "#FFF" },
                }}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/thebiomaskcompany/",
                    "_blank"
                  )
                }
              >
                <InstagramIcon htmlColor="#A4A4A4" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white md:px-16 md:py-5 p-5">
        <div className="flex w-full md:flex-row flex-col-reverse items-center md:justify-between gap-5">
          <div className="flex flex-col md:flex-row items-center basis-1/2">
            <div className="text-black font-normal text-sm md:text-left text-center">
              Copyright © {moment().format("YYYY")} {t("footer.rights")}
            </div>
            <div
              className="md:hidden flex flex-col items-center gap-1 cursor-pointer"
              onClick={goToTop}
            >
              <KeyboardArrowUpRoundedIcon htmlColor="#000" />
              <div className="text-black uppercase font-normal text-base">
                {t("footer.top")}
              </div>
            </div>
          </div>
          <div
            className="md:flex hidden flex-col items-center gap-1 cursor-pointer"
            onClick={goToTop}
          >
            <KeyboardArrowUpRoundedIcon htmlColor="#000" />
            <div className="text-black uppercase font-normal text-base">
              {t("footer.top")}
            </div>
          </div>
          <div className="flex flex-row items-center gap-3 basis-1/2 justify-end">
            <a
              href="https://www.vipaconnect.com"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row gap-3 items-center mr-3"
            >
              <div className="text-black font-normal text-sm">
                Desenvolvido por:
              </div>
              <LogoVipaConnect className="w-28 h-fit" />
            </a>
            <div className="text-black font-normal text-sm">Powered by:</div>
            <LogoBiomaskCompany />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
