import "./App.css";
import Footer from "./resources/components/landingpage/footer";
import NavigationAppBar from "./resources/components/navigation/navbar";
import PolicyPrivacy from "./resources/pages/privacy";
import Complaint from "./resources/pages/privacy/complaint";
import Gallery from "./resources/pages/gallery/gallery";
import LandingPage from "./routes/landingpage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <NavigationAppBar />
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path={`/privacy-policy`} element={<PolicyPrivacy />} />
        <Route path={`/complaint`} element={<Complaint />} />
        <Route path={`/gallery`} element={<Gallery />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
