import React from "react";
import About from "./components/About";
import Collab from "./components/Collab";
import Header from "./components/Header";
import Mission from "./components/Mission";
import Partners from "./components/Partners";
import Process from "./components/Process";
import SandwichMaterial from "./components/SandwichMaterial";
import Team from "./components/Team";
import TechnicalInfo from "./components/TechnicalInfo";

function LandingPage() {
  return (
    <>
      <Header />
      <Mission />
      <Collab />
      <About />
      <Process />
      <SandwichMaterial />
      <Team />
      <Partners />
      <TechnicalInfo />
    </>
  );
}

export default LandingPage;
