import { useTranslation } from "react-i18next";
import FileFooter from "../../pages/landingpage/images/file-footer.svg";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

export const FilePlaceHolder = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full relative z-1 max-w-7xl mx-auto">
      <div className="bg-white p-5 w-full box-border text-lg">
        <ul className="pl-8 font-normal text-[#3A3A3A] font-[Inter]">
          <li className="list-disc">
            {t("technical.text.1")}
            <span className="font-semibold">
              bioMASK nº M1420-01-0247-FEDER-000052
            </span>
          </li>
          <li className="list-disc">{t("technical.text.2")}</li>
          <li className="list-disc">{t("technical.text.3")}</li>
          <li className="list-disc">{t("technical.text.4")}</li>
          <li className="list-disc">{t("technical.text.5")}</li>
          <li className="list-disc">{t("technical.text.6")}</li>
          <li className="list-disc">{t("technical.text.7")}</li>
          <li className="list-disc">{t("technical.text.8")}</li>
          <li className="list-disc">{t("technical.text.9")}</li>
        </ul>
        <div className="flex flex-col gap-2 md:flex-row justify-between items-end">
          <div className="pl-3 mt-8">
            <p className="text-[#3A3A3A] font-normal mb-5">
              {t("technical.funded")}
            </p>
            <img src={FileFooter} alt="" />
          </div>
          <a href="/Ficha_Projeto_bioMASK.pdf" target="_blank" rel="noreferrer">
            <span>
              {t('technical.getFile')} <PictureAsPdfOutlinedIcon />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};
