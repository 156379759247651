import styled from "styled-components";
// import HeroImage from "../../../resources/pages/landingpage/images/hero.png";

export const HeroBackground = styled("div")`
  width: 100%;
  min-height: 100vh;
  background: #f8cf55;
  position: relative;
`;

export const ScrollIconWrap = styled("div")`
  position: absolute;
  bottom: 10%;
  & svg {
    position: relative;

    & #scroll #ball {
      animation: move-up 3s ease-in-out infinite;
      position: absolute;

      @keyframes move-up {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
          translate: 0 -15px;
        }
        90% {
          translate: 0 3px;
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
`;
