import { Accordion } from "@mui/material";
import styled from "styled-components";

export const CustomAccordion = styled(Accordion)`
  background: #f8f9fa !important;
  transition: all 0.3s ease-in-out !important;
  &.Mui-expanded {
    background-color: #669581 !important;
    & .MuiAccordionSummary-content {
      color: #fff;
      font-weight: 700;
      font-size: 1.125rem;
    }
    & svg {
      color: #fff !important;
    }
  }
  & svg {
    color: #669581 !important;
  }
  &.MuiAccordion-root {
    &::before {
      display: none;
    }
  }
  & .MuiAccordionSummary-content {
    color: #669581;
    font-weight: 700;
    font-size: 1.125rem;
  }
  & .MuiAccordionDetails-root {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
