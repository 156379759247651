import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwiper } from "swiper/react";
import Alexandra from "../../assets/team/Alexandra.png";
import Amit from "../../assets/team/Amit.png";
import Carla from "../../assets/team/Carla.png";
import Carlos from "../../assets/team/Carlos.png";
import Claudia from "../../assets/team/Claudia.png";
import Dirk from "../../assets/team/Dirk.png";
import Duarte from "../../assets/team/Duarte.png";
import Emanuel from "../../assets/team/Emanuel.png";
import Harshit from "../../assets/team/Harshit.png";
import Hugo from "../../assets/team/Hugo.png";
import Jeremy from "../../assets/team/Jeremy.png";
import Maria from "../../assets/team/Maria.png";
import Oina from "../../assets/team/Oina.png";
import Ricardo from "../../assets/team/Ricardo.png";
import Shujoy from "../../assets/team/Shujoy.png";
import Tatiana from "../../assets/team/Tatiana.png";
import Vitor from "../../assets/team/Vitor.png";

const TeamItem = ({ member, className, onClick, index, currentMember }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    HandleLogo(member);
  }, [member]);

  function HandleLogo(member) {
    switch (member) {
      case "tatiana":
        return <img src={Tatiana} alt="" className="object-contain" />;
      case "shujoy":
        return <img src={Shujoy} alt="" className="object-contain" />;

      case "carlos":
        return <img src={Carlos} alt="" className="object-contain" />;

      case "carla":
        return <img src={Carla} alt="" className="object-contain" />;

      case "maria":
        return <img src={Maria} alt="" className="object-contain" />;

      case "duarte":
        return <img src={Duarte} alt="" className="object-contain" />;

      case "oinamo":
        return <img src={Oina} alt="" className="object-contain" />;

      case "hugo":
        return <img src={Hugo} alt="" className="object-contain" />;

      case "claudia":
        return <img src={Claudia} alt="" className="object-contain" />;

      case "alexandra":
        return <img src={Alexandra} alt="" className="object-contain" />;

      case "emanuel":
        return <img src={Emanuel} alt="" className="object-contain" />;

      case "vitor":
        return <img src={Vitor} alt="" className="object-contain" />;

      case "jeremy":
        return <img src={Jeremy} alt="" className="object-contain" />;

      case "dirk":
        return <img src={Dirk} alt="" className="object-contain" />;

      case "ricardo":
        return <img src={Ricardo} alt="" className="object-contain" />;

      case "amit":
        return <img src={Amit} alt="" className="object-contain" />;

      case "harshit":
        return <img src={Harshit} alt="" className="object-contain" />;

      default:
        break;
    }
  }

  const { t } = useTranslation();
  const swiper = useSwiper();

  return (
    <div
      style={{
        backgroundColor: index === currentMember ? "#EBE0E7" : "#FFFFFF",
      }}
      className={`mx-5 py-12 h-full transition-all duration-300 ${
        index === currentMember && "scale-110"
      }`}
    >
      <div
        onClick={() => {
          swiper.slideTo(index);
          handleOpen();
        }}
        className={`${className} w-[240px] rounded-full flex flex-col items-center justify-start relative z-[1] gap-3 cursor-pointer mx-auto`}
      >
        <div className="w-[200px] h-[200px] rounded-full overflow-hidden bg-[#E8EAEB] flex items-center justify-center">
          <div className="w-full h-full">{HandleLogo(member)}</div>
        </div>
        <div className="font-[Inter]">
          <p className="text-center font-semibold text-black">
            {t(`team.people.${member}.name`)}
          </p>
          <p className="text-center text-black">
            {t(`team.people.${member}.description`)} <br />{" "}
            {t(`team.people.${member}.enterprise`)}
          </p>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClick={handleOutsideClick}
      >
        <div
          onClick={handleOutsideClick}
          className="md:fixed md:inset-0 flex items-center justify-center font-[Inter]"
        >
          <div className="bg-white overflow-hidden md:rounded-lg md:justify-center items-center flex flex-col h-screen w-screen md:max-w-screen-md md:w-full md:h-fit">
            <div className="flex flex-col items-stretch md:flex-row w-full h-full overflow-y-scroll md:overflow-y-hidden">
              <div className="w-full justify-end flex md:hidden">
                <CloseIcon onClick={handleClose} fontSize="large" />
              </div>
              <div className="md:h-full min-h-[308px] md:min-h-full overflow-hidden mx-auto w-10/12 md:w-7/12 bg-[#DBD3D8] flex-col flex md:justify-end px-5">
                {HandleLogo(member)}
              </div>
              <div className="flex flex-col items-center py-5 px-8 md:w-7/12">
                <div className="md:max-h-[400px] md:overflow-y-scroll px-2 flex flex-col items-center no-scrollbar">
                  <h2 id="modal-title" className="font-semibold mb-2 text-xl">
                    {t(`team.people.${member}.name`)}
                  </h2>
                  <p id="modal-description" className="text-lg text-center">
                    {t(`team.people.${member}.description`)}
                  </p>
                  <p id="modal-description" className="text-lg text-center">
                    {t(`team.people.${member}.enterprise`)}
                  </p>

                  <p className="text-justify text-base mt-5 ">
                    {t(`team.people.${member}.text`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TeamItem;
