import React from "react";
import DesktopBg from "../../../resources/assets/header/desktop.mp4";
import MobileBg from "../../../resources/assets/header/mobile.mp4";
import { HeroBackground } from "../styles/index-styles";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <HeroBackground className="items-center flex">
      <video
        autoPlay
        muted
        loop
        className="h-screen w-screen relative object-cover hidden lg:block"
      >
        <source src={DesktopBg} type="video/mp4" />
      </video>

      <video
        autoPlay
        muted
        loop
        className="h-screen w-screen relative object-cover visible lg:hidden"
      >
        <source src={MobileBg} type="video/mp4" />
      </video>
      <div className="z-30 absolute w-full bottom-0 left-0 flex-row text-[#FFFFFF] justify-center items-center mb-10 hidden md:flex">
        <div className="flex px-4 py-2 after:rounded-br-md after:border-b-2 after:border-r-2 after:border-[#FDD354] after:h-4/6 after:w-full after:content-[''] after:bottom-0 after:absolute after:left-0 relative">
          <span className="w-full">{t("header1")}</span>{" "}
        </div>
        <div className="flex px-4 py-2 -mx-[2px] relative after:rounded-tl-md after:rounded-tr-md after:border-t-2 after:border-l-2 after:border-r-2 after:border-[#FDD354] after:h-5/6 after:w-full after:content-[''] after:top-0 after:absolute after:left-0">
          <span className="w-full">{t("header2")}</span>
        </div>
        <div className="flex px-4 py-2 relative after:rounded-bl-md after:border-b-2 after:border-l-2 after:border-[#FDD354] after:h-4/6 after:w-full after:content-[''] after:bottom-0 after:absolute after:left-0">
          <span className="w-full">{t("header3")}</span>
        </div>
      </div>
      {/* <table className="z-30 absolute w-full bottom-0 left-0 flex flex-row text-[#FFFFFF] justify-center items-center mb-10">
        <tr>
          <td className="border-[#FDD354] p-4 border-b-[3px] border-r-[3px] rounded-md">
            FFP2/N95 compliance
          </td>
          <td className="border-[#FDD354] p-4 border-r-[3px] border-l-[3px] border-t-[3px] rounded-md">
            Biodegradability
          </td>
          <td className="border-[#FDD354] p-4 border-b-[3px] border-l-[3px] rounded-md">
            Proprietary sandwich material made with banana fiber
          </td>
        </tr>
      </table> */}
    </HeroBackground>
  );
};

export default Header;
